import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';

export const Header: React.FC = () => {
  return (
    <Box as="footer" bg="brand.300" color="white" py="16px">
      <Flex alignItems="center" justifyContent="center">
        <Box as="a" href="/">
          <Heading size="lg" fontWeight="bold" color="white" textTransform="uppercase">
            Viresmo
          </Heading>
        </Box>
      </Flex>
    </Box>
  );
};
