type FooterLink = {
  label: string;
  href: string;
};

export const footerLinks: FooterLink[] = [
  { label: 'Algemene voorwaarden', href: '/policies/terms-conditions' },
  { label: 'Privacy beleid', href: '/policies/privacy-policy' },
  { label: 'Cookie beleid', href: '/policies/cookie-policy' },
];
