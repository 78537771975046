import { Box, Flex, Wrap, WrapItem } from '@chakra-ui/react';
import * as React from 'react';

import { footerLinks } from './data';

// import { LanguageSwitcher } from './LanguageSwitcher';

export interface FooterProps {}

export const Footer = () => (
  <Box as="footer" bg="brand.300" color="white" py="64px">
    <Box maxW="7xl" px="8" mx="auto">
      <Flex
        direction={{ base: 'column-reverse', lg: 'row' }}
        align={{ base: 'flex-start', lg: 'center' }}
        justify="space-between"
        fontSize="sm"
      >
        <Wrap id="bottom" spacing={{ base: '4', lg: '8' }} mt={{ base: '4', lg: '0' }}>
          <WrapItem>
            <Box as="a" href="/">
              &copy; Viresmo
            </Box>
          </WrapItem>
          {footerLinks.map((link, idx) => (
            <WrapItem key={idx}>
              <Box as="a" href={link.href}>
                {link.label}
              </Box>
            </WrapItem>
          ))}
        </Wrap>
        {/* <LanguageSwitcher /> */}
      </Flex>
    </Box>
  </Box>
);
