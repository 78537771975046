import { Box, Button, Flex, Img, Text } from '@chakra-ui/react';
import * as React from 'react';

interface ProductCardProps {
  image: string;
  name: string;
  href: string;
  children: React.ReactNode;
}

export const ProductCard = (props: ProductCardProps) => {
  const { image, name, children, href } = props;

  return (
    <Flex justifyContent="center">
      <Box maxW="20rem">
        <Flex justifyContent="center" alignItems="center">
          <Img pos="relative" alt={name} w="32" h="32" objectFit="cover" src={image} zIndex="1" border="none" />
        </Flex>
        <Text fontWeight="bold" fontSize="2xl" mt="4" as="a" href={href}>
          {name}
        </Text>
        <Box mt="2" color="black">
          {children}
        </Box>
        <Flex justifyContent="flex-end">
          <Button colorScheme="green" mt="6" as="a" href={href}>
            Meer informatie
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
